/* eslint-disable @typescript-eslint/camelcase */
import {
  IProductsSortOption,
  IProductSortDirection,
} from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Catalog/Products/Sort/Sort'
import { IFilterItem } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Catalog/Navigation/Navigation'
import {
  IUnbxdProduct,
  IUnbxdProductResponse,
} from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { getCookie } from '../cookie'
import { ILocationCookieType } from '../../components/StoreLocator/interfaces'
import { IUnbxdSearchQueryResponse } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import {
  IProductImageType,
  IProductImage,
  IProductPDF,
  IVendor,
  IAttribute,
} from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import uuid from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/uuid'
import {
  diff,
  find,
  isEmpty,
} from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import appendQueryString from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'
import { maxProductsToLoad, VARIANTS_PAGE_SIZE } from '../../settings/variables'
import { delay } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/delay'
import {
  onUnbxdCategoryFacets,
  onUnbxdSearchFacets,
  onUnbxdSearchImpression,
} from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/Unbxd/UnbxdController'
import { ICategoryBreadcrumb } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS/categories'

/**
 * Determines all the sort options we have for products
 */
const defaultProductSortOptions: IProductsSortOption[] = [
  {
    label: 'Relevance',
    value: 'default',
  },
  {
    label: 'Best Sellers',
    value: 'BESTSELLER_RANK',
  },
  {
    label: 'Prices: Low to High',
    value: 'price_lowest',
    direction: IProductSortDirection.ASCENDING,
  },
  {
    label: 'Prices: High to Low',
    value: 'price_highest',
    direction: IProductSortDirection.DESCENDING,
  },
]

interface IFilter {
  value: string
  label: string
}
export interface ISearchQuery {
  q?: string | string[]
  filter: IFilter[]
  sort: string
  start?: number
  rows?: number
}

const getActiveSortOptionFromUrl = (query: ISearch | undefined): string => {
  if (!query?.sort) {
    return 'default'
  }
  if (query.sort.indexOf('finalPrice') !== -1) {
    if (query.sort.indexOf('asc') !== -1) {
      return 'price_lowest'
    } else {
      return 'price_highest'
    }
  }
  return query.sort.replace(/asc|desc/g, '').trim()
}

/**
 * Gets called when a user selects a different sort option from the select field
 * @param sortBy
 */
const onProductSortSelected = (sortBy: IProductsSortOption, search: ISearch): ISearch => {
  const { storeCode } = JSON.parse(getCookie(ILocationCookieType.STORE_CODES, null))
  const parsed: ISearch = search
  const isPriceSort: boolean = sortBy.value.indexOf('price_') !== -1
  if (sortBy.value !== 'default') {
    parsed.sort = `${isPriceSort ? `finalPrice_${storeCode}` : sortBy.value} ${sortBy.direction || 'asc'}`
  } else {
    parsed.sort = ''
  }
  return parsed
}

/**
 * Helper method that will convert facets from the api into a format that our merce components lie
 * @param filter
 */
const formatProductFilter = (filter: IFilter): IFilterItem => {
  const split: string[] = filter.value.split(':')
  const key: string = split[0]
  const value: string = unescape(split[1])
  const title: string = filter.label
  return {
    key,
    value,
    title,
  }
}

interface IUnbxd {
  track: (type: string, obj: { [x: string]: any }) => void
}

const debug: boolean = false
const log = (message: string) => {
  if (debug) {
    // tslint:disable-next-line:no-console
    console.log(`UNBXD: ${message}`)
  }
}

const getUnbxd = (): IUnbxd | null => {
  if (window) {
    const win: any = window as any
    const Unbxd = win.Unbxd
    return retryToFindUnbxd(Unbxd, 0)
  } else {
    log('Warning: No Window Present')
  }
  return null
}

const retryToFindUnbxd = (Unbxd: IUnbxd, counter: number): IUnbxd | null => {
  if (Unbxd && Unbxd.track) {
    return Unbxd
  } else {
    if (counter > 4) {
      log('Warning: Unbxd not present on window')
      return null
    }
    setTimeout(() => {
      retryToFindUnbxd(Unbxd, ++counter)
    }, 100)
    return null
  }
}

const onUnbxdBrowseImpression = (query: string, pageType: string, skus: string[]) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    Unbxd.track('browse_impression', {
      page: query,
      page_type: pageType,
      pids_list: skus,
    })
    log(`browse_impression: ${query}:${skus?.length}}`)
  }
}

const fieldsThatNeedsToBeKeepedOnParent = [
  'CYLINDO_MATERIAL_CD',
  'ACCENT_COLOR_STOCK_SKU',
  'ACCENT_COLOR_STOCK_SKU2',
  'ACCENT_COLOR_STOCK_SKU3',
  'RELATED_ITM_CD_LIST',
  'RELATED_TYPE',
  'IMAGE360',
  'COLOR',
  'DELIVERY_QUALIFICATION',
  'BESTSELLER_RANK',
  'VENDOR_TYPE',
]

const adaptMorProductResponse = (
  data: IUnbxdProductResponse,
  attrs: IAttribute[],
  vendors: IVendor[],
  setSkusInfo?: any[],
) => {
  const products: IUnbxdProduct[] = data?.products
  for (const product of products) {
    // ------------------- NAME -----------------------
    if (!product.name) {
      product.name = product?.title || ''
    }

    // ------------------ FIELDS --------------------
    product.fields = []
    if (attrs) {
      for (const attr of attrs) {
        const code = attr.code
        const targetValue: any = product[code]
        if (targetValue) {
          const something: any = {
            fieldValue: Array.isArray(targetValue) ? targetValue.join(', ') : targetValue,
            attribute: {
              ...attr,
            },
          }
          product.fields.push(something)
          if (!fieldsThatNeedsToBeKeepedOnParent.includes(code)) {
            delete product[code]
          }
        }
      }
    }

    // ------------------ SET SKUS --------------------

    if (setSkusInfo) {
      product.setSkus = setSkusInfo
    }

    // ------------------- VENDOR ---------------------------
    if (product.vendor && vendors) {
      const vendor: IVendor | undefined = find(vendors, {
        name: product.vendor,
      })
      if (vendor) {
        product.vendor = vendor
      }
    }

    // ----------------- CATEGORIES ----------------
    product.categories = []
    let i: number = 0
    const crumbs: string[] = []
    for (i = 1; i < 10; i++) {
      const hasCategory: string[] | undefined = product[`categoryPath${i}`]
      if (!hasCategory) {
        break
      } else {
        crumbs.push(hasCategory[0])
      }
    }
    for (const crumb of crumbs) {
      product.categories.push({
        title: crumb,
      } as any)
    }

    // ------------------ DESCRIPTION ---------------------
    if (product.longDescription) {
      product.fullDescription = {
        html: product.longDescription,
        css: '',
      }
    }

    // ------------------- THUMBNAIL ----------------------
    if (!product.thumbnail && product.images) {
      const images: any = product.images
      const imagesAsObject = JSON.parse(images)
      product.images = { items: imagesAsObject.items }
      if (product.images.items[0]) {
        product.thumbnail = product.images?.items[0]?.url
        product.thumbnailAlt = product.images?.items[0]?.alt
      }
    }

    // ----------------- IMAGES ---------------------------
    let images: {
      items: IProductImage[]
    } = { items: [] }

    if (!product.images && product?.imageUrl?.length > 0) {
      for (const unbxdImage of product.imageUrl) {
        images.items.push({
          id: uuid(),
          url: unbxdImage,
          alt: product.name,
          type: IProductImageType.IMAGE,
          thumbnail: unbxdImage,
        })
      }
    } else {
      images = product.images as any
    }
    product.images = images || null

    if (product.pdfs) {
      let pdfs: {
        items: IProductPDF[]
      } = { items: [] }
      const strigifiedPdfs: string = product.pdfs as any
      pdfs = JSON.parse(strigifiedPdfs)
      product.pdfs = pdfs
    }
  }
  return data
}

const adaptMorUnbxdProduct = (results: IUnbxdSearchQueryResponse) => {
  let i: number = 1
  if (results?.response?.products?.length) {
    for (const product of results.response.products) {
      product.name = product.name !== undefined ? product.name : product.title
      product.prank = i.toString()
      if (product.images) {
        const images: any = product.images
        const imagesAsObject = JSON.parse(images)
        product.images = { items: imagesAsObject.items }
        if (product.images.items[0]) {
          product.thumbnail = product.images?.items[0]?.url
          product.thumbnailAlt = product.images?.items[0]?.alt
        }
      }
      i++
    }
  }
  return results?.response?.products || []
}

const adaptUnbxdProductVariants = (results: IUnbxdSearchQueryResponse) => {
  const products: IUnbxdProduct[] = results.response.products

  for (const product of products) {
    if (product.variants?.length) {
      product.variants = addProductsMetadata(product.variants as IUnbxdProduct[])
    }
  }

  results.response.products = products

  return products
}

const addProductsMetadata = (products: IUnbxdProduct[]) => {
  for (const product of products) {
    product.name = product.name || product.title

    if (product.images) {
      const images: any = product.images
      const imagesAsObject = JSON.parse(images)
      product.images = { items: imagesAsObject.items }
      if (product.images.items[0]) {
        product.thumbnail = product.images.items[0].url
        product.thumbnailAlt = product.images.items[0].alt
      }
    }
  }

  return products
}

const filterProductsByLeadAttribute = (products: IUnbxdProduct[], parent: IUnbxdProduct) => {
  const leadAttribute: string = parent.leadAttribute
  const uniqueLeadAttributeOptionsProducts: IUnbxdProduct[] = []
  const existingValues: any[] = []
  if (leadAttribute) {
    products.forEach((p: IUnbxdProduct) => {
      if (p[leadAttribute]) {
        const leadAttributeArray = Array.isArray(p[leadAttribute]) ? p[leadAttribute] : [p[leadAttribute]]

        const isPresent = existingValues.some((value: any[]) => {
          const valueArray = Array.isArray(value) ? value : [value]

          return !diff(valueArray, leadAttributeArray).length && !diff(leadAttributeArray, valueArray).length
        })

        if (!isPresent) {
          uniqueLeadAttributeOptionsProducts.push(p)
          existingValues.push(p[leadAttribute])
        }
      } else {
        // when there's a product group which has a lead attribute set
        // but some/all of it's variants (products) don't have any value for that
        // attribute, still build the products array
        // this is a patch so that the ui doesn't break
        // ideal solution is every product having a value for it's group's lead attribute
        uniqueLeadAttributeOptionsProducts.push(p)
      }
    })
    return uniqueLeadAttributeOptionsProducts
  } else {
    return [parent]
  }
}

const adaptProductRecommendationsResponse = (products: IUnbxdProduct[]) => {
  try {
    for (const product of products) {
      if (!product.name) {
        product.name = product.title
      }

      // ------------------- THUMBNAIL ----------------------
      if (!product.thumbnail && product.images && (product.images as any)[0] !== '') {
        const images: any = product.images
        const imagesAsObject = JSON.parse(images)
        product.images = { items: imagesAsObject.items }
        if (product.images.items[0]) {
          product.thumbnail = product.images?.items[0]?.url
          product.thumbnailAlt = product.images?.items[0]?.alt
        }
      }
    }
  } catch (e) {
    console.log(e?.response?.data || e)
  } finally {
    return products
  }
}

const adaptUnbxdCartProduct = (product: any) => {
  product.name = product.name ? product.name : product.title
  const images: string = product.images
  const imagesAsObject = JSON.parse(images)
  product.images = { items: imagesAsObject.items }
  if (product.images.items[0]) {
    product.thumbnail = product.images?.items[0]?.url
    product.thumbnailAlt = product.images?.items[0]?.alt
  }
  return product
}

/**
 * Encoding a filter or an array of filters with the format key: "label1 & label2" (contains the " & " character) for
 * the filter to be applied correclty on unbxd api call.
 */

const encodeFilters = (filter: string | string[]) => {
  if (Array.isArray(filter)) {
    return filter.map(f => {
      return encodeAmpersandOnFilter(f)
    })
  }
  return encodeAmpersandOnFilter(filter)
}

const encodeAmpersandOnFilter = (filter: string) => {
  const split = filter.split(':')
  const encodedFilters = split.map(s => encodeURIComponent(s))
  return encodedFilters.join(':')
}

const encodeAmpersandOnUrl = (url: string) => {
  return url?.replace(' & ', '%20%26%20')
}

const decodeAmpersandOnUrl = (url: string) => {
  return url?.replace('%20%26%20', ' & ')
}

const removeHash = (query: string) => {
  if (query.startsWith('#')) {
    query = query.substr(1)
  }

  return query
}

const appendCommonQueryStrings = (newQuery: string, start?: number, useVariantsRelevant?: boolean) => {
  newQuery = appendQueryString(newQuery, { start: start || '0' })
  newQuery = appendQueryString(newQuery, { rows: maxProductsToLoad })
  newQuery = appendQueryString(newQuery, { variants: true })
  newQuery = appendQueryString(newQuery, { 'variants.count': VARIANTS_PAGE_SIZE })
  if (useVariantsRelevant) {
    newQuery = appendQueryString(newQuery, { 'variants.relevant': true })
  }
  newQuery = appendQueryString(newQuery, { 'facet.multiselect': true })
  return newQuery
}

const generateCrumbPath = (breadcrumbs: ICategoryBreadcrumb[] | undefined, encode: boolean): string => {
  const crumbPath: string = breadcrumbs
    ? breadcrumbs
        .map((b: ICategoryBreadcrumb) => {
          return encode ? encodeURIComponent(b?.title) : b?.title
        })
        .join('>')
    : ''
  return crumbPath
}

const makeImpression = async (
  products: IUnbxdProduct[],
  query: any,
  search: ISearch,
  breadcrumbs: ICategoryBreadcrumb[],
) => {
  await delay(1000)
  const facets: { [x: string]: any } = { ...query }
  const productSkus = products?.map(p => p.uniqueId ?? p.sku).filter(p => p != null)
  facets.filter = search.filter
  const page = breadcrumbs ? generateCrumbPath(breadcrumbs, false) : query.slug
  if (!isEmpty(facets)) {
    onUnbxdCategoryFacets(page, 'BOOLEAN', facets.filter)
  }
  onUnbxdBrowseImpression(page, 'BOOLEAN', productSkus)
}

const makeImpressionSearch = async (products: IUnbxdProduct[], search: ISearch) => {
  const queryObject: ISearch = search
  await delay(1000)
  const productSkus = products?.map(p => p.uniqueId ?? p.sku).filter(p => p != null)
  onUnbxdSearchImpression(search.q as string, productSkus)
  if (queryObject.filter) {
    onUnbxdSearchFacets(search.q as string, queryObject.filter)
  }
}

const buildFilterGroupForMutiValueFacet = (filter: any[], filterGroup?: any) => {
  // USE OR QUERY TO FILTER PRODUCT MULTI VALUES IN SAME FACET
  filter?.forEach((element: any) => {
    if (!filterGroup[element.split(':')[0]]) {
      filterGroup[element.split(':')[0]] = element
    } else {
      filterGroup[element.split(':')[0]] = filterGroup[element.split(':')[0]] + ' OR ' + element
    }
  })

  return filterGroup
}

export {
  getActiveSortOptionFromUrl,
  defaultProductSortOptions,
  formatProductFilter,
  onProductSortSelected,
  adaptMorUnbxdProduct,
  adaptProductRecommendationsResponse,
  adaptMorProductResponse,
  adaptUnbxdCartProduct,
  onUnbxdBrowseImpression,
  encodeFilters,
  encodeAmpersandOnUrl,
  decodeAmpersandOnUrl,
  removeHash,
  adaptUnbxdProductVariants,
  filterProductsByLeadAttribute,
  appendCommonQueryStrings,
  makeImpression,
  makeImpressionSearch,
  generateCrumbPath,
  buildFilterGroupForMutiValueFacet,
}

export type ISearch = ISearchQuery
export type IFacetFilter = IFilter
